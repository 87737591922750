import React, { useEffect, useState } from 'react'
import Header from './Header'
import { useStore } from '../store/Store'
import { useTranslation } from 'react-i18next'
import logo from '../image/logo_test.svg'
import login_error from '../image/login_error.svg'
import logo_dark from '../image/logo_test_dark.svg'
import BigLoader from './BigLoader'
import { InputMask } from '@react-input/mask';
import { checkPython, checkUsernamePhone } from '../host/Config'
import { message } from 'antd'
import {BsFillEyeSlashFill, BsFillEyeFill} from 'react-icons/bs'
export default function Main({level, setlevel, username,loginByPassword, setusername,password, setpassword, phone, setphone}) {

  const [type, settype]=useState(1)
  const [showPassword, setshowPassword]=useState(false)
  const bigloader=useStore(state=>state.bigloader)
  const setbigloader=useStore(state=>state.setbigloader)
  const setvideo=useStore(state=>state.setvideo)
  const dark=useStore(state=>state.dark)
  const setuser=useStore(state=>state.setuser)
  const setfaceDetectionLogin=useStore(state=>state.setfaceDetectionLogin)
  const faceDetectionLogin=useStore(state=>state.faceDetectionLogin)
  const {t} =useTranslation()

  useEffect(()=>{
    checkPython()
    
     setTimeout(() => {
        setbigloader(false)
     }, 0);
  },[])
  
  const editStart=()=>{
    
   if(username.length>=4 || phone.length===19){
    
    if(username.length>=4){
      var config={username, phone:null}
    }else if(phone.length===19){
      var config={username:null, phone:phone.replaceAll("+","").replaceAll(" ","").replaceAll("(","").replaceAll(")","").replaceAll("-","")}
    }
    checkUsernamePhone(config).then(res=>{
      
     if(!res.data.fTestPermission){
      message.error({content:t("no_student_test"), duration:4, icon:<img src={login_error}/>})
     }
     if(res.data.fTestPermission || (!res.data.fTestPermission && res.data.last_test)){
          setfaceDetectionLogin(res.data.faceDetectionLogin)
        setusername(res.data.username)
        setuser(null)  
        }
     if(res.data.faceDetectionLogin){
          setvideo(true);
          setlevel(1)
        }
      
     
     }).catch(err=>{
       if(username.length>=4){
        message.error({content:t("no_student_username"), duration:4, icon:<img src={login_error}/>})
   
    }else if(phone.length===19){
      message.error({content:t("no_student_phone"), duration:4, icon:<img src={login_error}/>})
    }
    })
    
        }
     }

  
  return (
    <>
     {bigloader?<BigLoader/>:<></>}
      <Header level={level} />
        <div className={`center`}>
        <div className='back_main'></div>
          <div className='center_row'>
            <div className='center_item'>
            <img src={dark?logo_dark:logo}/>
                <p className='center_text_main' dangerouslySetInnerHTML={{__html:t("Haydovchilikka tayyorlash va qayta tayyorlash bo'yicha Nazariy imtihonlarni inson omilisiz qabul qiluvchi <b>E-TEST</b> tizimi")}}></p>
            </div>
            <div className='center_item'>
            <span className='text_span'>{t("Tizimga kirish uchun")}</span>
            <div className='main_form'>
              <div className='main_form_head'>
              <div onClick={()=>{settype(1); setphone("+998 "); setusername(""); setfaceDetectionLogin(true); setpassword("")}} className={`main_form_head_item ${type===1?"active_main":""}`}>
                
                <div className='main_form_img login_icon'></div>
                <p>{t("login")}</p>
                </div>
               <div onClick={()=>{settype(2); setphone("+998 "); setusername(""); setfaceDetectionLogin(true); setpassword("")}} className={`main_form_head_item ${type===2?"active_main":""}`}>
               
                <div className='main_form_img telefon_icon'></div>
                <p>{t("telefon")}</p>
                </div>
               
              </div>
              <div className='main_form_body'>
                {type===1? <input autoFocus autoComplete="new-password" value={username} onChange={(e)=>{setusername(e.target.value); setfaceDetectionLogin(true); setpassword("")}} type='text'/>:
                 <InputMask autoComplete="new-password" autoFocus  mask="+998 (__) ___-__-__" replacement={{ _: /\d/ }} placeholder='+998 (99) 000-00-00' value={phone} onChange={(e)=>{setphone(e.target.value);  setfaceDetectionLogin(true); setpassword(""); setusername("")}} type='text'/>}

             <div className={`passwordDiv ${faceDetectionLogin?"disPassword":""}`}>
              <p>{t("password")}</p>
              {!faceDetectionLogin?<input autoComplete="new-password" autoFocus type={showPassword?"text":"password"} value={password} onChange={(e)=>setpassword(e.target.value)} />:<></>}
              <button><span onClick={()=>setshowPassword(!showPassword)}>{showPassword?<BsFillEyeFill/>:<BsFillEyeSlashFill/>}</span></button>
             </div> 
             
               
               <div>
                <div className={`main_form_btn_box ${((username.length<4 && phone.length<19 && faceDetectionLogin) || (!faceDetectionLogin && password.length<2))?"btn_cam_dis":""}`}>
                  <div className='main_form_btn_icon face_detect_icon'></div>
                  {faceDetectionLogin? <button className={`btn_cam`} onClick={editStart}>{t("Shaxsni tasdiqlash")}</button>:
                   <button className={`btn_cam`} onClick={loginByPassword}>{t("Kirish")}</button>}
                 
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          
 






    </div>
    <p className='text_error' dangerouslySetInnerHTML={{__html:t('error_p')}}></p>
        <div className='footer'>
          <a href="tel:+998712079294" className='footer_box'>
       <div className='footer_img'></div>
        <b>71 2079294</b>
        </a>
        </div>
    </>
  )
}
